import { createTheme, lighten } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { alpha } from "@mui/system";
import { deepmerge } from "@mui/utils";

const palette = {
	primary: {
		light: "#D5E1FE",
		main: "#495EFD",
		dark: "#232173",
		contrastText: "#ffffff",
		50: "#F4F7FE",
	},
	white: {
		main: "#ffffff",
		contrastText: "#232173",
	},
	text: {
		primary: "#232173", // Same color as primary.dark
		secondary: "#BDBDBD",
		main: "#262626",
	},
	background: {
		default: "#FFFFFF",
	},
	success: {
		light: "#34A853",
		main: "#2E7D32",
		dark: "#1B5E20",
	},
	warning: {
		light: "#FFBC1D",
		main: "#FFBC1D",
		dark: "#FFBC1D",
	},
	navyBlue: {
		50: "#F5F6FF",
		100: "#E5E8FF",
		200: "#CDD2FE",
		300: "#9AA6FE",
		400: "#6879FD",
		500: "#495EFD",
		600: "#0320FC",
		700: "#0219CA",
		800: "#021397",
		900: "#010D65",
		950: "#161836",
	},
	neutral: {
		0: "#FFFFFF",
		25: "#F9FAFB",
		50: "#F3F4F6",
		100: "#EAECF0",
		200: "#E2E5EA",
		300: "#C4CAD4",
		400: "#A8B0BE",
		500: "#8A95A8",
		600: "#6C7A93",
		700: "#576275",
		800: "#414958",
		900: "#2B313B",
		950: "#21252C",
	},
	danger: {
		50: "#FAEAEB",
		100: "#F6D5D7",
		200: "#ECACAF",
		300: "#E38287",
		400: "#D9595F",
		500: "#D02F37",
		600: "#A6262C",
		700: "#7D1C21",
		800: "#531316",
		900: "#2A090B",
	},
	orange: {
		50: "#FFF7E5",
		100: "#FFF0CC",
		200: "#FFE199",
		300: "#FFD466",
		400: "#FFC333",
		500: "#FFB300",
		600: "#CC9000",
		700: "#996C00",
		800: "#664800",
		900: "#332400",
	},
	green: {
		50: "#EBF9F3",
		100: "#D8F3E8",
		200: "#B1E7D1",
		300: "#8ADBB9",
		400: "#63CFA2",
		500: "#3CC38B",
		600: "#309C6F",
		700: "#247553",
		800: "#184E37",
		900: "#0C271C",
	},
	sementics: {
		default: "#18181B",
	},
};

const typography = {
	fontFamily: [
		"DM Sans",
		"Comfortaa",
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	// https://type-scale.com/ 1.2000 minor third
	h1_regular: {
		fontFamily: "DM Sans",
		fontSize: "48px",
		lineHeight: "52px",
		fontWeight: 400,
		color: palette.primary.dark,
	},
	h1_medium: {
		fontFamily: "DM Sans",
		fontSize: "48px",
		lineHeight: "52px",
		fontWeight: 500,
		color: palette.primary.dark,
	},
	h1_bold: {
		fontFamily: "DM Sans",
		fontSize: "48px",
		lineHeight: "52px",
		fontWeight: 700,
		color: palette.primary.dark,
	},
	h2_regular: {
		fontFamily: "DM Sans",
		fontSize: "34px",
		lineHeight: "38px",
		fontWeight: 400,
		color: palette.primary.dark,
	},
	h2_medium: {
		fontFamily: "DM Sans",
		fontSize: "34px",
		lineHeight: "38px",
		fontWeight: 500,
		color: palette.primary.dark,
	},
	h2_bold: {
		fontFamily: "DM Sans",
		fontSize: "34px",
		lineHeight: "38px",
		fontWeight: 700,
		color: palette.primary.dark,
	},
	h3_regular: {
		fontFamily: "DM Sans",
		fontSize: "32px",
		lineHeight: "36px",
		fontWeight: 400,
		color: palette.primary.dark,
	},
	h3_medium: {
		fontFamily: "DM Sans",
		fontSize: "32px",
		lineHeight: "36px",
		fontWeight: 500,
		color: palette.primary.dark,
	},
	h3_bold: {
		fontFamily: "DM Sans",
		fontSize: "32px",
		lineHeight: "36px",
		fontWeight: 700,
		color: palette.primary.dark,
	},
	h4_regular: {
		fontFamily: "DM Sans",
		fontSize: "24px",
		lineHeight: "32px",
		fontWeight: 400,
		color: palette.primary.dark,
	},
	h4_medium: {
		fontFamily: "DM Sans",
		fontSize: "24px",
		lineHeight: "32px",
		fontWeight: 500,
		color: palette.primary.dark,
	},
	h4_bold: {
		fontFamily: "DM Sans",
		fontSize: "24px",
		lineHeight: "32px",
		fontWeight: 700,
		color: palette.primary.dark,
	},
	body1_regular: {
		fontFamily: "DM Sans",
		fontSize: "20px",
		lineHeight: "24px",
		fontWeight: 400,
	},
	body1_medium: {
		fontFamily: "DM Sans",
		fontSize: "20px",
		lineHeight: "24px",
		fontWeight: 500,
	},
	body1_semibold: {
		fontFamily: "DM Sans",
		fontSize: "20px",
		lineHeight: "24px",
		fontWeight: 600,
	},
	body1_bold: {
		fontFamily: "DM Sans",
		fontSize: "20px",
		lineHeight: "24px",
		fontWeight: 700,
	},
	body2_regular: {
		fontFamily: "DM Sans",
		fontSize: "18px",
		lineHeight: "21px",
		fontWeight: 400,
	},
	body2_medium: {
		fontFamily: "DM Sans",
		fontSize: "18px",
		lineHeight: "21px",
		fontWeight: 500,
	},
	body2_semibold: {
		fontFamily: "DM Sans",
		fontSize: "18px",
		lineHeight: "21px",
		fontWeight: 600,
	},
	body2_bold: {
		fontFamily: "DM Sans",
		fontSize: "18px",
		lineHeight: "21px",
		fontWeight: 700,
	},
	body3_regular: {
		fontFamily: "DM Sans",
		fontSize: "16px",
		lineHeight: "21px",
		fontWeight: 400,
	},
	body3_medium: {
		fontFamily: "DM Sans",
		fontSize: "16px",
		lineHeight: "21px",
		fontWeight: 500,
	},
	body3_semibold: {
		fontFamily: "DM Sans",
		fontSize: "16px",
		lineHeight: "21px",
		fontWeight: 600,
	},
	body3_bold: {
		fontFamily: "DM Sans",
		fontSize: "16px",
		lineHeight: "21px",
		fontWeight: 700,
	},
	body4_regular: {
		fontFamily: "DM Sans",
		fontSize: "14px",
		lineHeight: "18px",
		fontWeight: 400,
	},
	body4_medium: {
		fontFamily: "DM Sans",
		fontSize: "14px",
		lineHeight: "18px",
		fontWeight: 500,
	},
	body4_semibold: {
		fontFamily: "DM Sans",
		fontSize: "14px",
		lineHeight: "18px",
		fontWeight: 600,
	},
	body4_bold: {
		fontFamily: "DM Sans",
		fontSize: "14px",
		lineHeight: "18px",
		fontWeight: 700,
	},
	body5_regular: {
		fontFamily: "DM Sans",
		fontSize: "12px",
		lineHeight: "16px",
		fontWeight: 400,
	},
	body5_medium: {
		fontFamily: "DM Sans",
		fontSize: "12px",
		lineHeight: "16px",
		fontWeight: 500,
	},
	body5_semibold: {
		fontFamily: "DM Sans",
		fontSize: "12px",
		lineHeight: "16px",
		fontWeight: 600,
	},
	body5_bold: {
		fontFamily: "DM Sans",
		fontSize: "12px",
		lineHeight: "16px",
		fontWeight: 700,
	},
	caption1_regular: {
		fontFamily: "DM Sans",
		fontSize: "10px",
		lineHeight: "15px",
		fontWeight: 400,
	},
	caption1_medium: {
		fontFamily: "DM Sans",
		fontSize: "10px",
		lineHeight: "15px",
		fontWeight: 500,
	},
	caption1_bold: {
		fontFamily: "DM Sans",
		fontSize: "10px",
		lineHeight: "15px",
		fontWeight: 700,
	},
	caption2_regular: {
		fontFamily: "DM Sans",
		fontSize: "8px",
		lineHeight: "12px",
		fontWeight: 400,
	},
	caption2_medium: {
		fontFamily: "DM Sans",
		fontSize: "8px",
		lineHeight: "12px",
		fontWeight: 500,
	},
	caption2_bold: {
		fontFamily: "DM Sans",
		fontSize: "8px",
		lineHeight: "12px",
		fontWeight: 700,
	},
	button: {
		textTransform: "none" as const,
	},
};

const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1440,
	},
};

const customShadows = [
	"0px 0px 20px #6560602e", // Shadow from the previous design system
	`0px 4px 80px 0px ${alpha("#000000", 0.04)}`, // Regular shadow used through the app
	"0px 0px 4px 0px #002E99", // Focused navy blue button
	"0px 0px 4px 0px #484686", // Focused lavender button
	"0px 0px 4px 0px #D9595F", // Focused danger button
	"0px 24px 48px -8px #10182829", // Tooltip shadow
	"0px 0px 0px 4px #E5E8FF", // Input shadow
	"0px 0px 0px 4px #FAEAEB", // Input shadow (error state)

	...Array(17).fill(""),
];
const theme = createTheme({
	// @ts-expect-error Target requires 25 element(s) but source may have fewer.
	shadows: ["none", ...(customShadows as string[])],
	palette,
	typography,
	breakpoints,
});

const components: ThemeOptions["components"] = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				height: "100vh",
			},
			body: {
				height: "100vh",

				"& > #root": {
					height: "100%",
				},
				"& button": {
					borderWidth: 0,
				},
			},
			a: {
				textDecoration: "none",
			},
		},
	},
	MuiCard: {
		defaultProps: {
			elevation: 1,
		},
		styleOverrides: {
			root: {
				borderRadius: theme.spacing(2.5),
			},
		},
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				"&:last-child": {
					paddingBottom: theme.spacing(2),
				},
			},
		},
	},
	MuiCardActions: {
		styleOverrides: {
			root: {
				paddingBottom: theme.spacing(2),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},
		},
	},
	// Shared across all button types (Button, IconButton, ...)
	MuiButtonBase: {
		styleOverrides: {
			root: {
				"&:disabled": {
					cursor: "not-allowed",
					pointerEvents: "initial",
				},
			},
		},
	},
	MuiButton: {
		defaultProps: {
			size: "large",
			disableElevation: true,
			disableRipple: true,
		},
		variants: [
			// [NAVY BLUE] PRIMARY
			{
				props: { variant: "primary", color: "navyBlue" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.navyBlue[500],
					color: theme.palette.neutral[0],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[3],
						borderColor: theme.palette.navyBlue[200],
					},

					":hover": {
						backgroundColor: theme.palette.navyBlue[700],
					},

					":active": {
						backgroundColor: theme.palette.navyBlue[800],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [NAVY BLUE] SECONDARY
			{
				props: { variant: "secondary", color: "navyBlue" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.navyBlue[100],
					color: theme.palette.navyBlue[700],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[3],
						borderColor: theme.palette.navyBlue[700],
					},

					":hover": {
						color: theme.palette.navyBlue[800],
						backgroundColor: theme.palette.navyBlue[200],
					},

					":active": {
						color: theme.palette.navyBlue[800],
						backgroundColor: theme.palette.navyBlue[400],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [NAVY BLUE] TERTIARY
			{
				props: { variant: "tertiary", color: "navyBlue" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.neutral[100],
					color: theme.palette.navyBlue[600],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[3],
						borderColor: theme.palette.navyBlue[600],
					},

					":hover": {
						backgroundColor: theme.palette.navyBlue[100],
					},

					":active": {
						color: theme.palette.navyBlue[700],
						backgroundColor: theme.palette.navyBlue[200],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [LAVENDER] PRIMARY
			{
				props: { variant: "primary", color: "lavender" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.navyBlue[800],
					color: theme.palette.neutral[0],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[3],
						borderColor: theme.palette.navyBlue[200],
					},

					":hover": {
						backgroundColor: theme.palette.navyBlue[700],
					},

					":active": {
						backgroundColor: theme.palette.navyBlue[900],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [LAVENDER] SECONDARY
			{
				props: { variant: "secondary", color: "lavender" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.navyBlue[100],
					color: theme.palette.navyBlue[800],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[4],
						borderColor: theme.palette.navyBlue[800],
					},

					":hover": {
						color: theme.palette.navyBlue[900],
						backgroundColor: theme.palette.navyBlue[200],
					},

					":active": {
						color: theme.palette.neutral[0],
						backgroundColor: theme.palette.navyBlue[300],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [LAVENDER] TERTIARY
			{
				props: { variant: "tertiary", color: "lavender" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: "transparent",
					color: theme.palette.navyBlue[800],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[4],
						borderColor: theme.palette.navyBlue[800],
					},

					":hover": {
						backgroundColor: theme.palette.navyBlue[100],
					},

					":active": {
						color: theme.palette.navyBlue[800],
						backgroundColor: theme.palette.navyBlue[300],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [LAVENDER] GHOST
			{
				props: { variant: "ghost", color: "lavender" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius,
					backgroundColor: "transparent",
					color: theme.palette.navyBlue[800],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[4],
						borderColor: theme.palette.navyBlue[800],
					},

					":hover": {
						backgroundColor: "transparent",
						textDecoration: "underline",
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
					},
				}),
			},

			// [DANGER] PRIMARY
			{
				props: { variant: "primary", color: "danger" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.danger[400],
					color: theme.palette.neutral[0],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[5],
						borderColor: theme.palette.danger[900],
					},

					":hover": {
						backgroundColor: theme.palette.danger[50],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [DANGER] SECONDARY
			{
				props: { variant: "secondary", color: "danger" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					backgroundColor: theme.palette.neutral[100],
					color: theme.palette.danger[50],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[5],
						borderColor: theme.palette.danger[50],
					},

					":hover": {
						backgroundColor: theme.palette.danger[900],
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
						backgroundColor: theme.palette.neutral[200],
					},
				}),
			},

			// [DANGER] GHOST
			{
				props: { variant: "ghost", color: "danger" },
				style: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius,
					backgroundColor: "transparent",
					color: theme.palette.danger[400],
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "transparent",

					":focus-visible": {
						boxShadow: theme.shadows[5],
						borderColor: theme.palette.danger[400],
					},

					":hover": {
						backgroundColor: "transparent",
						textDecoration: "underline",
					},

					":disabled, :disabled:hover": {
						color: theme.palette.neutral[400],
					},
				}),
			},

			// SIZE
			{
				props: { size: "large" },
				style: {
					...theme.typography.body3_regular,
					padding: theme.spacing(1.5, 3),
					minWidth: 160,
				},
			},
			{
				props: { size: "medium" },
				style: {
					...theme.typography.body4_regular,
					padding: theme.spacing(1, 2),
				},
			},
			{
				props: { size: "small" },
				style: {
					...theme.typography.body5_regular,
					padding: theme.spacing(0.5, 1),
				},
			},
			{
				props: { size: "large", variant: "ghost" },
				style: { padding: 0, minWidth: 0 },
			},
			{
				props: { size: "medium", variant: "ghost" },
				style: { padding: 0 },
			},
			{
				props: { size: "small", variant: "ghost" },
				style: { padding: 0 },
			},
		],
		styleOverrides: {
			root: {
				borderWidth: 2,
				borderRadius: 8,
			},
			sizeSmall: {},
			sizeMedium: {},
			sizeLarge: {},
			outlinedPrimary: {
				// The default border color for outlined button is transparent by 50%.
				borderColor: theme.palette.primary.main,
				backgroundColor: theme.palette.common.white,
				"&:hover:not(.Mui-disabled)": {
					color: theme.palette.common.white,
					backgroundColor: theme.palette.primary.main,
				},
			},
			outlinedSecondary: {
				// The default border color for outlined button is transparent by 50%.
				borderColor: theme.palette.secondary.main,
				backgroundColor: theme.palette.common.white,
				"&:hover:not(.Mui-disabled)": {
					color: theme.palette.common.white,
					backgroundColor: theme.palette.secondary.main,
				},
			},
			textPrimary: {
				color: theme.palette.primary.dark,
			},
			startIcon: {
				marginRight: theme.spacing(0.5),
			},
			endIcon: {
				marginLeft: theme.spacing(0.5),
			},
		},
	},
	MuiTab: {
		styleOverrides: {
			root: {
				color: theme.palette.primary.main,
				fontWeight: theme.typography.h1_bold.fontWeight,

				paddingLeft: theme.spacing(6),
				paddingRight: theme.spacing(6),
				borderTopLeftRadius: theme.spacing(1),
				borderTopRightRadius: theme.spacing(1),

				"&.Mui-selected": {
					color: theme.palette.primary.dark,
					backgroundColor: theme.palette.primary.light,
				},

				// Without the :not selector, it will apply to the selected tab on hover
				"&:hover:not(.Mui-selected)": {
					backgroundColor: lighten(theme.palette.primary.light, 0.5),
					transition: "0.3s",
				},
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				padding: "0 2px", //top-bottom left-right
				fontSize: theme.typography.caption1_regular.fontSize,
				borderWidth: 0,
			},
		},
	},
	MuiTextField: {
		defaultProps: {
			variant: "filled",
		},
	},
	MuiFilledInput: {
		defaultProps: {
			disableUnderline: true,
		},
		styleOverrides: {
			root: {
				color: theme.palette.neutral[950],
				backgroundColor: theme.palette.common.white,
				borderBottomLeftRadius: theme.spacing(1),
				borderBottomRightRadius: theme.spacing(1),
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				color: theme.palette.text.secondary,
				"&.Mui-focused": {
					color: theme.palette.text.secondary,
				},
			},
		},
	},
	MuiFormControl: {
		defaultProps: {
			variant: "filled",
		},
	},
	MuiTypography: {
		styleOverrides: {
			gutterBottom: {
				marginBottom: theme.spacing(4),
			},
		},
		defaultProps: {
			variantMapping: {
				h1_regular: "h1",
				h1_medium: "h1",
				h1_bold: "h1",

				h2_regular: "h2",
				h2_medium: "h2",
				h2_bold: "h2",

				h3_regular: "h3",
				h3_medium: "h3",
				h3_bold: "h3",

				h4_regular: "h4",
				h4_medium: "h4",
				h4_bold: "h4",

				body1_regular: "p",
				body1_medium: "p",
				body1_semibold: "p",
				body1_bold: "p",

				body2_regular: "p",
				body2_medium: "p",
				body2_semibold: "p",
				body2_bold: "p",

				body3_regular: "p",
				body3_medium: "p",
				body3_semibold: "p",
				body3_bold: "p",

				body4_regular: "p",
				body4_medium: "p",
				body4_semibold: "p",
				body4_bold: "p",

				body5_regular: "p",
				body5_medium: "p",
				body5_semibold: "p",
				body5_bold: "p",

				caption1_regular: "span",
				caption1_medium: "span",
				caption1_bold: "span",

				caption2_regular: "span",
				caption2_medium: "span",
				caption2_bold: "span",
			},
		},
	},
	MuiToolbar: {
		styleOverrides: {
			root: {
				minHeight: "unset",
				padding: theme.spacing(2.5),
				color: theme.palette.text.primary,
			},
		},
	},
	MuiStepLabel: {
		styleOverrides: {
			iconContainer: {
				paddingRight: theme.spacing(2),
			},
		},
	},
	MuiAvatar: {
		styleOverrides: {
			rounded: {
				borderRadius: 8,
			},
		},
	},
	MuiLink: {
		styleOverrides: {
			root: {
				cursor: "pointer",
			},
		},
	},
	MuiAlert: {
		styleOverrides: {
			root: {
				fontSize: theme.typography.body3_regular.fontSize,
				padding: theme.spacing(2, 3),
				borderRadius: theme.spacing(1),
			},
			standardInfo: {
				color: theme.palette.common.white,
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
	MuiAlertTitle: {
		styleOverrides: {
			root: {
				fontSize: theme.typography.h4_regular.fontSize,
			},
		},
	},
	MuiRating: {
		styleOverrides: {
			sizeLarge: {
				fontSize: "40px",
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			sizeMedium: {
				fontSize: theme.typography.body3_regular.fontSize,
			},
		},
	},
	MuiDialog: {
		styleOverrides: {
			root: {
				"& > .MuiDialog-paper": {
					borderRadius: 99,
					margin: 0,
				},
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				borderBottomWidth: 1,
				borderBottomColor: theme.palette.neutral[300],
				borderBottomStyle: "solid",
			},
		},
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				borderTopWidth: 1,
				borderTopColor: theme.palette.neutral[300],
				borderTopStyle: "solid",
				justifyContent: "center",
			},
		},
	},
};

export default deepmerge(theme, {
	components,
});
