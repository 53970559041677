import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import HelpIcon from "@mui/icons-material/HelpOutlineRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ReceiptInfoDialog from "~/components/organisms/ReceiptInfo/ReceiptInfoDialog";
import { RECEIPT_IDS } from "~/constants";

const ReceiptInfoButton = () => {
	const { t } = useTranslation("receipts");

	const [isBannerOpen, toggleBanner] = useToggle(false);

	return (
		<Box
			display="flex"
			justifyContent="flex-end"
			px={2}
			id={RECEIPT_IDS.INFO_BUTTON}
		>
			<Button
				variant="tertiary"
				color="navyBlue"
				endIcon={<HelpIcon sx={{ height: 14, width: 14 }} />}
				sx={(theme) => ({
					...theme.typography.body5_medium,
					color: theme.palette.neutral[700],
					backgroundColor: theme.palette.neutral[50],
					minWidth: 0,
					px: 1,
					py: 0.5,
					borderRadius: 16,
				})}
				onClick={toggleBanner}
			>
				{t("receipts_info_modal.receipts_info_button")}
			</Button>
			<ReceiptInfoDialog
				open={isBannerOpen}
				closeModal={() => toggleBanner()}
			/>
		</Box>
	);
};

export default ReceiptInfoButton;
