import type { PropsWithChildren, ReactElement } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import { css, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

type PopOverListItemContainerProps = {
	selected?: boolean;
};

const PopOverListItemContainer = styled("div", {
	shouldForwardProp: (propName) => propName !== "selected",
})<PopOverListItemContainerProps>(
	({ theme, selected }) => css`
		display: flex;
		padding: ${theme.spacing(1)};
		border-radius: 999px;
		cursor: pointer;
		min-width: 150px;
		align-items: center;
		gap: 8px;

		${selected &&
		css`
			background-color: ${theme.palette.navyBlue[100]};
		`}
	`,
);

type Props = PopOverListItemContainerProps & {
	icon?: ReactElement;
	onClick: () => void;
};

const PopOverListItem = (props: PropsWithChildren<Props>) => {
	const { icon, selected, onClick, children } = props;

	return (
		<PopOverListItemContainer selected={selected} onClick={onClick}>
			{icon}

			<Typography variant="body3_regular" color="navyBlue.900" width="100%">
				{children}
			</Typography>

			{selected && <CheckCircleIcon sx={{ fontSize: "16px" }} />}
		</PopOverListItemContainer>
	);
};

export default PopOverListItem;
