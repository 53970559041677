import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "wouter";

import ChangeLanguage from "~/components/molecules/ChangeLanguage";
import { APP_ROUTES } from "~/constants";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<Stack
			pt={2}
			pb={3}
			spacing={2}
			divider={<span>|</span>}
			direction="row"
			alignItems="center"
			justifyContent="center"
		>
			<ChangeLanguage />

			<Typography
				variant="body5_regular"
				textAlign="center"
				component={Link}
				to={APP_ROUTES.PRIVACY_POLICY}
			>
				{t("privacy_policy")}
			</Typography>

			<Typography
				variant="body5_regular"
				textAlign="center"
				component="a"
				href="https://noticia.ai/conditions-generales-dutilisation/"
				target="_blank"
				rel="noreferrer"
			>
				{t("terms_of_use")}
			</Typography>
		</Stack>
	);
};

export default Footer;
